<script setup lang="ts">
import type { InputTypeHTMLAttribute } from "vue"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  icon?: boolean
  type?: InputTypeHTMLAttribute
  name?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  leading?: boolean
  trailing?: boolean
  autofocus?: boolean
  autofocusDelay?: number
}>()
defineEmits(["input", "blur", "change"])
const value = defineModel<string>()
const input = ref<HTMLInputElement>()

function sixChars(value: string) {
  const chars = ["", "", "", "", "", ""]
  for (const [i, letter] of value?.split("").entries())
    chars[i] = letter
  return chars
}

const autoFocus = () => {
  if (props.autofocus)
    input.value?.focus()
}

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      autoFocus()
    }, props.autofocusDelay || 100)
  }
})
</script>

<template>
  <div class="relative">
    <input
      ref="input"
      v-model="value"
      v-bind="$attrs"
      type="text"
      autocomplete="one-time-code"
      spellcheck="false"
      pattern="[a-zA-Z0-9]+"
      required
      maxlength="6"
      class="monospace h-20 w-full rounded-2xl bg-transparent p-4 text-3xl leading-loose tracking-[48.5px] text-transparent caret-$global-blue-primary outline-none ring-none focus:ring-none"
    >
    <div class="pointer-events-none absolute top-0 z-100 h-20 w-full flex flex items-center gap-3 text-3xl">
      <div
        v-for="(letter, key) of sixChars(value || '')"
        :key
        class="h-full w-full flex items-center justify-center rounded-xl text-center uppercase ring ring-2"
        :class="{
          'ring-$menu-b-input-default': !letter,
          'ring-$global-blue-primary': !!letter,
        }"
      >
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
</style>
